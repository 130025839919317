<template>
  <v-bottom-sheet
    inset
    max-width="400px"
    scrollable
    v-model="upgradeStore.upgrade"
  >
    <UpgradeCard />
  </v-bottom-sheet>
</template>

<script>
import { useUpgradeStore } from "@/stores/upgrade";
import UpgradeCard from "@/components/upgrade/UpgradeCard.vue";

export default {
  name: "UpgradeBottomSheet",
  components: {
    UpgradeCard,
  },
  setup() {
    const upgradeStore = useUpgradeStore();
    return { upgradeStore };
  },
};
</script>

<style scoped>
:deep(.v-bottom-sheet),
:deep(.v-bottom-sheet .v-card) {
  border-radius: 16px 16px 0 0;
}
</style>