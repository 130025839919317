import { defineStore } from "pinia"
import { useEditStore } from "@/stores/edit.js";
import axios from "axios"
import { API_URL } from "../api.js"
import { deepCopy } from "@/util/helpers";

export const useShortStore = defineStore("short", {
    getters: {
        youtubeVideoId: state => state.short.youtubeVideoId,
        googleDriveFileId: state => state.short.googleDriveFileId,
        videoUrl: state => state.short.videoUrl,
        shortId: state => state.short._id,
        shortLanguage: state => state.shortTranscript.transcriptLanguage
    },
    state: () => ({
        transcript: {},
        shorts: [],
        short: {},
        shortDetail: false,
        language: "auto",
        editSubtitles: false,
        shortTranscript: {},
        editedShortTranscript: null,
        editedShortTranscriptWords: null,
        searchValue: null,
    }),
    actions: {
        resetShortsHighlight() {
            this.shorts.forEach(short => {
                short.highlight = null
            })
        },
        async fetchShorts(youtubeVideoId, videoUrl) {
            try {
                let resp;
                if (youtubeVideoId) resp = await axios.get(`${API_URL}/shorts?youtubeVideoId=${youtubeVideoId}&language=${this.language}`, { withCredentials: true })
                else if (videoUrl) resp = await axios.get(`${API_URL}/shorts?videoUrl=${videoUrl}&language=${this.language}`, { withCredentials: true })
                if (resp.data.transcript.language && this.language !== `${resp.data.transcript.language}`) this.language = `${resp.data.transcript.language}`
                this.transcript = resp.data.transcript

                const sortedShorts = resp.data.shorts.slice().sort((a, b) => {
                    if (a.isManualClip && !b.isManualClip) return -1;
                    if (!a.isManualClip && b.isManualClip) return 1;
                    return (b.viralityScore || 0) - (a.viralityScore || 0);
                });

                let highlightIndex = 1;
                for (let i = 0; i < sortedShorts.length && highlightIndex <= 3; i++) {
                    const short = sortedShorts[i];
                    if (short.viralityScore && !short.isManualClip) sortedShorts[i].highlight = highlightIndex++;
                }

                this.searchValue = null
                this.shorts = sortedShorts
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchShort(shortId) {
            try {
                const editStore = useEditStore();
                editStore.fetchingShort = true;
                const resp = await axios.get(`${API_URL}/shorts/${shortId}`, { withCredentials: true })
                this.short = resp.data.short
                editStore.fetchingShort = false;
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchShortTranscript(shortId) {
            try {
                const editStore = useEditStore();
                editStore.fetchingTranscript = true;
                const resp = await axios.get(`${API_URL}/shorts/${shortId}/transcript`, { withCredentials: true })
                this.shortTranscript = resp.data.short
                this.editedShortTranscript = resp.data.short.transcript
                this.editedShortTranscriptWords = editStore.transcriptToTranscriptWords(resp.data.short.transcript)
                editStore.fetchingTranscript = false;
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchShortEmojis(shortId, emojisUrl) {
            try {
                const editStore = useEditStore();
                editStore.fetchingEmojis = true;

                const insertEmojis = (emojis) => {
                    const transcript = deepCopy(this.editedShortTranscript);
                    emojis.forEach(e => {
                        const transcriptLineIndex = transcript.findIndex(l => l.start === e.start);
                        if (transcriptLineIndex > -1) {
                            const originalText = transcript[transcriptLineIndex].text;
                            if (originalText.includes(e.word)) transcript[transcriptLineIndex].text = originalText.replace(e.word, `${e.emoji}${e.word}`)
                            else if (transcriptLineIndex < transcript.length - 1) transcript[transcriptLineIndex + 1].text = transcript[transcriptLineIndex + 1].text.replace(e.word, `${e.emoji}${e.word}`)
                        }
                    })
                    this.editedShortTranscript = deepCopy(transcript);
                    this.editedShortTranscriptWords = editStore.transcriptToTranscriptWords(deepCopy(transcript), editStore.subtitlesConfig.maxCharsPerBlock);
                }

                try {
                    const resp = await axios.get(emojisUrl, { withCredentials: false })
                    insertEmojis(resp.data)
                    editStore.fetchingEmojis = false;
                    return resp;
                } catch {
                    const resp = await axios.get(`${API_URL}/shorts/${shortId}/emojis`, { withCredentials: true })
                    insertEmojis(resp.data.short.emojis)
                    editStore.fetchingEmojis = false;
                    return resp
                }
            } catch (error) {
                console.log(error)
            }
        },
        processGenerateShortsData(data) {
            try {
                if (data.transcript.language && this.language !== `${data.transcript.language}`) this.language = `${data.transcript.language}`
                this.transcript = data.transcript
                if (!(data.shorts.length === 1 && data.shorts[0].isManualClip)) this.resetShortsHighlight()

                const sortedShorts = data.shorts.slice().sort((a, b) => {
                    if (a.isManualClip && !b.isManualClip) return -1;
                    if (!a.isManualClip && b.isManualClip) return 1;
                    return (b.viralityScore || 0) - (a.viralityScore || 0);
                });

                let highlightIndex = 1;
                for (let i = 0; i < sortedShorts.length && highlightIndex <= 3; i++) {
                    const short = sortedShorts[i];
                    if (short.viralityScore && !short.isManualClip) sortedShorts[i].highlight = highlightIndex++;
                }

                this.shorts.push(...sortedShorts)
                return;
            }
            catch (error) {
                console.log(error)
                throw error
            }
        }
    },
})