<template>
  <v-app>
    <div
      :class="
        $route.name === 'search' || $route.name === 'login'
          ? $vuetify.theme.dark
            ? 'gradient gradient-dark'
            : 'gradient gradient-light'
          : ''
      "
    >
      <v-system-bar v-if="$vuetify.breakpoint.lgAndUp && announcementBanner" app color="primary" height="48">
        <div class="mx-auto white--text">
          <span class="font-weight-bold">What's new?</span>
          <v-icon color="white" class="mx-1">mdi-circle-small</v-icon>
          Already have a clip in mind? Try manual clipping!
        </div>
        <v-btn small icon dark @click="closeAnnouncementBanner()">
          <v-icon small color="white" class="ma-0">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <TopNavigation />

      <v-main>
        <v-container fluid class="px-5 py-4 pt-0">
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>

    <v-snackbar
      v-model="snackbarStore.snackbar"
      :timeout="snackbarStore.timeout"
      bottom
      transition="slide-y-reverse-transition"
    >
      {{ snackbarStore.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text rounded v-bind="attrs" @click="snackbarStore.snackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="noticeSnackbar" :timeout="-1" bottom transition="slide-y-reverse-transition" color="accent">
      <!-- Sorry, we're currently experiencing issues with creating clips. Working
      hard to fix it as soon as possible. Thank you for your understanding. -->
      <!-- significant -->
      <!-- Sorry, we're experiencing slowdown in loading and trimming times of clips.
      Working hard to fix it as soon as possible. Thank you for your
      understanding. -->
      Apologies for the inconvenience. Our database is experiencing issues causing data loss. We're working hard to
      restore it. Thank you for your understanding.
      <template v-slot:action="{ attrs }">
        <v-btn text rounded v-bind="attrs" @click="noticeSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="['search', 'login'].includes($route.name)" class="privacy-link-wrapper">
      <a
        href="https://2short.ai/privacy"
        target="_blank"
        rel="noopener noreferrer"
        class="text-decoration-none text--disabled"
        >Privacy policy</a
      >
    </div>

    <Feedback />

    <Upgrade />

    <EditorControlsMobile v-if="$route.name === 'edit' && $vuetify.breakpoint.xs && editStore.initialized" />

    <div v-else class="btn-wrapper">
      <v-btn fab depressed x-small @click="changeTheme()">
        <v-icon>{{ $vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night" }}</v-icon>
      </v-btn>
      <v-btn
        v-if="$route.name === 'account' && accountStore.isLoggedIn"
        fab
        depressed
        x-small
        @click="feedbackStore.feedback = true"
        class="ml-2"
      >
        <v-icon>mdi-message-text</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useSnackbarStore } from "@/stores/snackbar";
import { useFeedbackStore } from "@/stores/feedback";
import { useEditStore } from "@/stores/edit";
import TopNavigation from "@/components/navigation/TopNavigation.vue";
import EditorControlsMobile from "@/components/edit/controls/EditorControlsMobile.vue";
import Feedback from "@/components/feedback/Feedback.vue";
import Upgrade from "@/components/upgrade/Upgrade.vue";

export default {
  components: {
    TopNavigation,
    EditorControlsMobile,
    Feedback,
    Upgrade
  },
  setup() {
    const accountStore = useAccountStore();
    const snackbarStore = useSnackbarStore();
    const feedbackStore = useFeedbackStore();
    const editStore = useEditStore();
    return { accountStore, snackbarStore, feedbackStore, editStore };
  },
  data() {
    return {
      noticeSnackbar: false,
      announcementBanner: false,
    };
  },
  async created() {
    if (localStorage.locale && ["en"].includes(localStorage.locale)) this.$i18n.locale = localStorage.locale;
    if (localStorage.theme && ["light", "dark"].includes(localStorage.theme))
      this.$vuetify.theme.dark = localStorage.theme === "dark";
    document.body.style.background = this.$vuetify.theme.dark ? "#121212" : "white";
  },
  async mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const authStatus = params.authStatus;
    if (authStatus === "success") {
      const csrfToken = window.location.hash.substring(1);
      localStorage.setItem("csrfToken", csrfToken);
      localStorage.removeItem("referrer");
      this.$http.defaults.headers.common["x-csrf-token"] = csrfToken;
      await this.accountStore.fetchLoggedInAccount();
      try {
        // eslint-disable-next-line no-undef
        gtag("event", "Login");
      } catch {
        console.log("Tracking disabled");
      }

      const viewPlans = localStorage.getItem("viewPlans");
      if (viewPlans === "true") {
        localStorage.removeItem("viewPlans");
        this.$router.push("/account?viewPlans=true");
      }
    } else if (authStatus === "error") {
      this.snackbarStore.text = this.$t("loginFailed");
      this.snackbarStore.snackbar = true;
    }

    if (!this.accountStore.isLoggedIn)
      // eslint-disable-next-line no-undef
      google.accounts.id.prompt();

    if (
      // this.accountStore.isLoggedIn &&
      // ["lite", "pro", "premium"].includes(this.accountStore.accountTier) &&
      Date.now() - (localStorage.announcementBannerCloseTimestamp || 0) >
      30 * 24 * 60 * 60 * 1000
    )
      this.announcementBanner = true;

    // if (window.location.pathname.startsWith("/edit/"))
    //   this.noticeSnackbar = true;
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      document.body.style.background = this.$vuetify.theme.dark ? "#121212" : "white";
      localStorage.theme = this.$vuetify.theme.dark ? "dark" : "light";
    },
    closeAnnouncementBanner() {
      this.announcementBanner = false;
      localStorage.announcementBannerCloseTimestamp = Date.now();
    },
  },
};
</script>

<style scoped>
.gradient {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-position: -20vw -25rem, -5vw -20rem;
  background-size: 80vw 80vw;
  background-repeat: no-repeat;
}

.gradient-light {
  background-image: radial-gradient(rgba(1, 105, 238, 0.25), rgba(255, 0, 0, 0) 30vw),
    radial-gradient(rgba(255, 156, 0, 0.25), rgba(255, 0, 0, 0) 30vw);
}

.gradient-dark {
  background-image: radial-gradient(rgba(1, 105, 238, 0.35), rgba(255, 0, 0, 0) 30vw),
    radial-gradient(rgba(255, 156, 0, 0.15), rgba(255, 0, 0, 0) 30vw);
}

@media (max-width: 576px) {
  .gradient {
    background-position: -20vw 0rem, 10vw 5rem;
  }
}

.privacy-link-wrapper {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.btn-wrapper {
  position: fixed;
  bottom: 16px;
  left: 16px;
  /* display: flex;
  flex-direction: column; */
}
</style>
