<template>
  <v-card>
    <v-card-title
      class="font-weight-bold d-flex align-center justify-space-between"
    >
      <span>{{ upgradeStore.title }}</span>
      <v-btn icon @click="upgradeStore.upgrade = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>{{ upgradeStore.subtitle }}</v-card-subtitle>
    <v-card-text>
      <v-card outlined flat class="rounded-lg">
        <v-card-title class="text-h5 font-weight-bold">
          <div>{{ $t("litePlan") }}</div>
          <v-spacer></v-spacer>
          <div>
            <span>${{ billingPeriod === "monthly" ? "9.90" : "99" }} </span>
            <span class="caption"
              >/
              {{
                billingPeriod === "monthly"
                  ? $t("month").toLowerCase()
                  : $t("year").toLowerCase()
              }}</span
            >
          </div>
        </v-card-title>
        <v-card-text>
          <!-- <div class="d-flex align-center">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            {{ $t("everythingInPlan", { plan: "Starter" }) }}
          </div> -->
          <div class="d-flex align-center">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            {{ $t("hoursOfAnalysisPerMonth", { hours: 5 }) }}
          </div>
          <div class="d-flex align-center mt-2">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            {{ $t("minutesOfFastExportsPerMonth", { minutes: 60 }) }}
          </div>
          <div class="d-flex align-center mt-2">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            {{ $t("multipleImportSources") }}
          </div>
          <div class="d-flex align-center mt-2">
            <v-icon color="primary" class="mr-2">mdi-check</v-icon>
            {{ $t("noAds") }}
          </div>
        </v-card-text>
      </v-card>
      <div class="caption mt-4">
        {{ upgradeStore.note }}
        <router-link
          to="/account?viewPlans=true"
          class="text-decoration-none"
          >{{ $t("viewAllPlans") }}</router-link
        >
      </div>
    </v-card-text>
    <v-card-actions class="pt-0 px-6 pb-4">
      <v-btn
        color="primary"
        depressed
        rounded
        block
        @click="upgradeSubscription('lite', billingPeriod)"
        :loading="loading"
        >{{ $t("upgradeNow") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useUpgradeStore } from "@/stores/upgrade";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "FeedbackCard",
  setup() {
    const accountStore = useAccountStore();
    const upgradeStore = useUpgradeStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, upgradeStore, snackbarStore };
  },
  data() {
    return {
      loading: false,
      billingPeriod: "monthly",
    };
  },
  methods: {
    async upgradeSubscription(tier, billingPeriod) {
      try {
        if (tier === "lite") this.loading = true;

        const resp = await this.accountStore.upgradeSubscription(
          tier,
          billingPeriod
        );

        try {
          // eslint-disable-next-line no-undef
          gtag("event", "SelectPlan");
        } catch {
          console.log("Tracking disabled");
        }

        window.location.href = resp.data.url;
      } catch (error) {
        this.loading = false;
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.btn-wrapper {
  width: 33%;
}
</style>