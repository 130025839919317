<template>
  <UpgradeBottomSheet v-if="$vuetify.breakpoint.smAndDown" />
  <UpgradeDialog v-else />
</template>

<script>
import UpgradeBottomSheet from "@/components/upgrade/UpgradeBottomSheet.vue";
import UpgradeDialog from "@/components/upgrade/UpgradeDialog.vue";

export default {
  name: "UpgradeComponent",
  components: {
    UpgradeBottomSheet,
    UpgradeDialog,
  },
};
</script>