<template>
  <v-dialog v-model="upgradeStore.upgrade" max-width="400px" scrollable>
    <UpgradeCard />
  </v-dialog>
</template>

<script>
import { useUpgradeStore } from "@/stores/upgrade";
import UpgradeCard from "@/components/upgrade/UpgradeCard.vue";

export default {
  name: "UpgradeDialog",
  components: {
    UpgradeCard,
  },
  setup() {
    const upgradeStore = useUpgradeStore();
    return { upgradeStore };
  },
};
</script>

<style scoped>
:deep(.v-dialog),
:deep(.v-dialog .v-card) {
  border-radius: 16px;
}
</style>