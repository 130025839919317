import { defineStore } from "pinia";

export const useUpgradeStore = defineStore("upgrade", {
  state: () => ({
    upgrade: false,
    title: "",
    subtitle: "",
    note: "",
  }),
  actions: {
    open(title, subtitle, note) {
      if (title) this.title = title;
      if (subtitle) this.subtitle = subtitle;
      if (note) this.note = note;
      this.upgrade = true;
    },
  },
});
